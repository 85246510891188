export const semesterOptionList = [
  {
    label: '第一学期',
    value: 1,
  },
  {
    label: '第二学期',
    value: 2,
  },
  {
    label: '第三学期',
    value: 3,
  },
  {
    label: '第四学期',
    value: 4,
  },
  {
    label: '第五学期',
    value: 5,
  },
  {
    label: '第六学期',
    value: 6,
  },
  {
    label: '第七学期',
    value: 7,
  },
  {
    label: '第八学期',
    value: 8,
  },
]

export const semesterEnum = semesterOptionList.reduce((sum: any, item: any) => {
  sum[item.value] = item.label
  return sum
}, {})

const fullYear = new Date().getFullYear()

export const gradeOptionList = Array.from({ length: 20 }).fill(0).map((_, index) => index - 10).map((v) => {
  return {
    label: `${fullYear + v}级`,
    value: fullYear + v,
  }
})

export const statusOptionList = [
  {
    label: '启用',
    value: 1,
  },
  {
    label: '禁用',
    value: 0,
  },
]

export const courseQualityEnum = {
  1: '必修',
  2: '选修',

}

export const courseTypeEnum = {
  1: '公共基础必修课',
  2: '通识教育必修课',
  3: '专业教育必修课',
  4: '创新创业课程',
  5: '公共基础选修课',
  6: '通知教育选修课',
  7: '专业教育选修课',
}
